/* About Page Container (Content) */
.about-container {
  font-family: 'Arial', sans-serif;
  width: 950px;  /* Content is constrained to 950px */
  margin: 0 auto;
  padding: 40px 20px;
  color: #333;
}

/* Hero Section */
.about-hero {
  color: white;
  text-align: center;
  padding: 60px 20px;
  margin-bottom: 30px;
}

.about-title {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #ff5722;
}

.about-subtitle {
  font-size: 1.5rem;
  margin-top: 0;
  color: #333;
}

/* About Us Section (Founder) */
.about-founder {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap; /* Ensures the image and text stack on smaller screens */
  align-items: center;
  justify-content: space-between;
}

.founder {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Stacks items on small screens */
}

.founder-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 70px;
}

.founder-details h2 {
  color: #ff5722;
  margin-bottom: 10px;
}

.founder-details p {
  color: #333;
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 600px;
}

/* Our Mission Section */
.about-mission {
  margin-bottom: 40px;
}

.mission-list {
  list-style-type: none;
  padding: 0;
}

.mission-list li {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.mission-list li::before {
  content: "•";
  position: absolute;
  left: 0;
  font-size: 2rem;
  color: #ff5722;
}

/* Our Vision Section */
.about-vision {
  margin-bottom: 40px;
}

.vision-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px; /* Even spacing between cards */
}

.vision-card {
  background: #f4f4f4;
  border-radius: 10px;
  padding: 15px;
  width: 25%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin-bottom: 20px;
}

.vision-card:hover {
  transform: translateY(-5px);
}

.vision-card h3 {
  color: #ff5722;
  margin-bottom: 10px;
}

.vision-card p {
  color: #333;
}

/* Meet Our Team Section */
.about-team {
  margin-bottom: 40px;
}

.team-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px; /* Adds even space between team member cards */
}

.team-card {
  background: #fff;
  border-radius: 10px;
  width: 20%;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin-bottom: 20px;
}

.team-card:hover {
  transform: translateY(-5px);
}

.team-photo {
  width: 100%;
  border-radius: 50%;
  max-width: 150px;
  margin-bottom: 10px;
  height: auto;
}

.team-name {
  font-size: 1.2rem;
  color: #333;
}

.team-role {
  font-size: 1rem;
  color: #ff5722;
  text-align: center;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .about-container {
    width: 100%;
    padding: 10px;
    margin-left: 20px;
  }

  .about-hero {
    padding: 50px 20px;
  }

  .about-title {
    font-size: 2.5rem;
  }

  .about-subtitle {
    font-size: 1.3rem;
  }

  .founder {
    flex-direction: column;
    text-align: center;
  }

  .founder-image {
    margin-right: 0;
  }

  .founder-details {
    text-align: center;
  }

  .mission-list li {
    font-size: 1rem;
  }

  .vision-cards {
    flex-direction: column;
    align-items: center;
  }

  .vision-card {
    width: 80%;
    margin-bottom: 20px;
  }

  .team-cards {
    flex-direction: column;
    align-items: center;
  }

  .team-card {
    width: 80%;
    margin-bottom: 20px;
  }

  .team-photo {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 480px) {
  .about-hero {
    padding: 40px 20px;
  }

  .about-title {
    font-size: 2rem;
  }

  .about-subtitle {
    font-size: 1.1rem;
  }

  .team-photo {
    width: 100px;
    height: 100px;
  }
}


/* Footer Full Width Fix */
.footer-container {
  width: 110%;
  padding: 0;
  margin: 0;
}

/* Prevent Horizontal Overflow */
body {
  overflow-x: hidden;
}
