/* General Contact Page Styles */
*,
*::before,
*::after {
  box-sizing: border-box; /* Prevent overflow issues */
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.contact-container {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  color: #333;
  max-width: 950px;
  margin: 0 auto;
  margin-top: 40px;
}

.contact-header {
  text-align: center;
  margin-bottom: 20px;
}

.contact-header h1 {
  font-size: 2.5rem;
  color: #ff5722;
}

.contact-header p {
  font-size: 1rem;
  color: #555;
}

/* Contact Form */
.contact-form {
  margin-bottom: 20px;
}

.contact-form h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #ff5722;
}

.form-field {
  margin-bottom: 15px;
}

.form-field label {
  font-size: 1rem;
  color: #555;
  display: block;
  margin-bottom: 5px;
}

.form-field input,
.form-field textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-field textarea {
  height: 130px;
}

.submit-btn {
  padding: 12px 20px;
  font-size: 1rem;
  background-color: #ff5722;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.submit-btn:hover {
  background-color: #e64a19;
}

/* Contact Information Section */
.contact-info {
  margin-bottom: 20px;
}

.contact-info h2 {
  font-size: 1.8rem;
  color: #ff5722;
  margin-bottom: 15px;
}

.contact-info p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

/* Google Map Section */
.contact-map iframe {
  border: 0;
  border-radius: 10px;
  width: 100%;
  height: 400px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .contact-container {
    padding: 20px; /* Reduce padding */
    width: 100%; /* Ensure full width on smaller screens */
    margin-left: 20px;
  }

  .contact-header h1 {
    font-size: 2rem; /* Smaller font size for mobile */
  }

  .contact-form h2 {
    font-size: 1.6rem;
  }

  .contact-info h2 {
    font-size: 1.6rem;
  }

  .form-field input,
  .form-field textarea {
    font-size: 0.9rem;
    width: 100%; /* Ensure input fields are full width */
  }

  /* Additional mobile adjustments */
  .contact-info p,
  .form-field label {
    font-size: 0.9rem;
  }

  /* Make sure iframe for the map is responsive */
  .contact-map iframe {
    height: 300px; /* Reduce map height for mobile view */
  }
}
