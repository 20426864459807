.contact-us {
    text-align: center;
    margin: 40px auto;
    padding: 20px;
    max-width: 950px;
    font-family: 'Arial', sans-serif;
  }
  
  .contact-us h2 {
    color: #ff5722;
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .contact-us p {
    color: #333;
    font-size: 1rem;
    margin-bottom: 30px;
  }
  
  .contact-form {
    margin-bottom: 40px;
  }
  
  form {
    display: grid;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid #f0f0f0;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
    transition: border-color 0.3s ease;
  }
  
  input:focus, textarea:focus {
    border-color: #ff5722;
    outline: none;
  }
  
  textarea {
    resize: none;
  }
  
  .submit-btn {
    background-color: #ff5722;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #e64a19;
  }
  
  .contact-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 30px;
  }
  
  .info-item {
    text-align: center;
    color: #333;
  }
  
  .info-item i {
    font-size: 2rem;
    color: #ff5722;
    margin-bottom: 10px;
  }
  
  .info-item p {
    margin: 0;
    font-size: 1rem;
  }
  
  /* Mobile View */
  @media (max-width: 600px) {
    .contact-us h2 {
      font-size: 1.6rem;
    }
  
    .contact-us p {
      font-size: 0.9rem;
    }
  
    .form-group label {
      font-size: 0.9rem;
    }
  
    .submit-btn {
      font-size: 0.95rem;
      padding: 12px;
    }
  
    .contact-info {
      grid-template-columns: 1fr; /* Stacking contact info in one column */
      gap: 15px;
    }
  
    .info-item i {
      font-size: 1.5rem;
    }
  
    .info-item p {
      font-size: 0.9rem;
    }
    input, textarea {
        width: 98.8%;
        padding: 10px;
        border: 2px solid #f0f0f0;
        border-radius: 5px;
        font-size: 1rem;
        color: #333;
        transition: border-color 0.3s ease;
      }
  }
  
  /* Tablet View (600px - 768px) */
  @media (min-width: 600px) and (max-width: 768px) {
    .contact-us h2 {
      font-size: 1.8rem;
    }
  
    .contact-us p {
      font-size: 1rem;
    }
  
    .submit-btn {
      font-size: 1rem;
      padding: 12px 20px;
    }
  
    .contact-info {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Flexibility for tablets */
    }
  
    .info-item i {
      font-size: 1.7rem;
    }
  
    .info-item p {
      font-size: 1rem;
    }
    
  }
  
  /* iPad or larger Tablets */
  @media (min-width: 768px) {
    .contact-us h2 {
      font-size: 2rem;
    }
  
    .contact-us p {
      font-size: 1rem;
    }
  
    .contact-info {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Multiple columns for iPad */
    }
  
    .info-item i {
      font-size: 2rem;
    }
  
    .info-item p {
      font-size: 1rem;
    }

    
  }
  