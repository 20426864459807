/* General Navbar Styles */
body {
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
  
  /* Navbar */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    background-color: #333333;
    color: #e64a19; /* Royal Navy Light Blue color */
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-sizing: border-box;
    height: 60px;
  }
  
  /* Logo */
  .nav-logo img {
    height: 40px;
    cursor: pointer;
  }
  
  /* Desktop Menu */
  .nav-items {
    display: flex;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
    flex-grow: 1;
    justify-content: center; /* Centering the menu items */
  }
  
  .nav-items li {
    position: relative;
  }
  
  .nav-items li a,
  .nav-items li .contact-btn {
    color: #e64a19; /* Royal Navy Light Blue */
    text-decoration: none;
    font-size: 18px;
    padding: 10px;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    position: relative;
  }
  
  /* Hover Effect for Menu Items */
  .nav-items li a:hover,
  .nav-items li .contact-btn:hover {
    color: white; /* Darker shade of Royal Navy Light Blue on hover */
  }
  
  /* Underline Animation */
  .nav-items li a::after,
  .nav-items li .contact-btn::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: #e64a19;
    transition: width 0.3s ease-in-out;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .nav-items li a:hover::after,
  .nav-items li .contact-btn:hover::after {
    width: 100%;
  }
  
  /* Contact Button */
  .contact-btn {
    background-color: transparent;
    border: 2px solid #e64a19; /* Update color to match theme */
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  

  .contact-btn desktop-only{
    color: #e64a19;
  }
  .contact-btn:hover {
    background-color: #e64a19;
    border-color: #e64a19;
    color: white;
  }
  
  /* Move Contact button to the right */
  .contact-btn.desktop-only {
    margin-left: auto; /* Move contact button to the right side */
    display: block;
  }
  
  /* Hamburger Menu */
  .hamburger-menu {
    display: none;
    flex-direction: column;
    gap: 6px;
    cursor: pointer;
    z-index: 1001;
  }
  
  .hamburger-menu .bar {
    width: 30px;
    height: 5px;
    background-color: #e64a19;
    border-radius: 3px;
  }
  
  /* Mobile Menu */
  .mobile-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100%;
    background-color: white;
    transition: right 0.3s ease-in-out;
    z-index: 1002;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
  }
  
  .mobile-menu.open {
    right: 0;
  }
  
  .mobile-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .mobile-menu li {
    padding: 15px;
  }
  
  .mobile-menu li a,
  .mobile-menu li .contact-btn {
    color: #e64a19;
    text-decoration: none;
    font-size: 18px;
    padding: 10px 20px;
    width: 100%;
    text-align: left;
  }
  
  .mobile-menu li a:hover,
  .mobile-menu li .contact-btn:hover {
    background-color: white;
    color: #e64a19;
  }
  
  /* Close Button */
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: white;
    cursor: pointer;
  }
  
  /* Mobile Menu Footer */
  .mobile-menu-footer {
    margin-top: auto;
    padding: 10px;
    background-color: #f1f1f1;
    text-align: center;
    font-size: 14px;
    color: #e64a19;
  }
  
  /* Overlay when mobile menu is open */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    /* Hide the desktop menu */
    .nav-items {
      display: none;
    }
  
    /* Show hamburger icon */
    .hamburger-menu {
      display: flex;
    }
  
    /* Hide the contact button in header on mobile */
    .contact-btn.desktop-only {
      display: none;
    }
  }
  

  /* Contact Button Style */
.contact-btn {
    background-color: transparent; /* Background remains transparent */
    color: #e64a19; /* Text color */
    border: 2px solid #e64a19; /* Border color to match the text */
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 50px; /* Rounded button */
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Hover and Focus Effect */
  .contact-btn:hover,
  .contact-btn:focus {
    background-color: #e64a19; /* Background becomes orange on hover */
    color: white; /* Text becomes white when hovered */
    border: 2px solid #e64a19;
    transform: translateY(-2px); /* Slight lift on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Shadow on hover */
  }
  
  /* Active (Clicked) Effect */
  .contact-btn:active {
    background-color: #e64a19; /* Solid orange background when clicked */
    color: white;
    border: 2px solid #e64a19;
    transform: translateY(0); /* Remove lift on click */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  /* Hide the button on mobile and tablet */
  .desktop-only {
    display: block; /* Ensure it's visible by default on larger screens */
  }
  
  /* For smaller screens, hide the contact button */
  @media (max-width: 768px) {
    .desktop-only {
      display: none;
    }
  }
  