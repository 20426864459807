.about-us {
    font-family: 'Arial', sans-serif;
    margin: 20px auto;
    padding: 20px;
    max-width: 950px;
  }
  
  /* General Styling */
  h2 {
    color: #ff5722;
    text-align: center;
    margin-bottom: 20px;
  }
  
  p {
    color: #333;
    line-height: 1.6;
  }
  
  /* Featured Activities Section */
  .cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .card {
    background-color: white;
    width: 280px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .card h3 {
    color: #ff5722;
    margin: 10px 0;
    font-size: 1.2rem;
  }
  
  .card p {
    color: #333;
    padding: 0 10px 10px;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }
  
  .our-activities-section {
    margin: 40px 0;
    text-align: center;
  }
  
  .our-activities-section h2 {
    color: #ff5722;
    margin-bottom: 30px;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .activities-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
  }
  
  .activity-card {
    background-color: white;
    border: 2px solid #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 20px;
    text-align: center;
  }
  
  .activity-icon {
    font-size: 2.5rem;
    color: #ff5722;
    margin-bottom: 15px;
  }
  
  .activity-card h3 {
    color: #ff5722;
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  .activity-card p {
    color: #333;
    font-size: 0.95rem;
    line-height: 1.5;
  }
  
  .activity-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    border-color: #ff5722;
  }
  
  
  /* Why Choose Us Section */
  .questions {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .question h3 {
    color: #ff5722;
  }
  
  .question p {
    color: #333;
  }
  
  /* How Can You Help Section */
  .how-can-you-help-section {
  text-align: center;
  margin: 40px auto;
  max-width: 950px;
}

.how-can-you-help-section h2 {
  color: #ff5722;
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: bold;
}

.help-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
}

.help-card {
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  background-color: white;
  transition: border-color 0.3s ease;
}

.help-card i {
  font-size: 2.5rem;
  color: #ff5722;
  margin-bottom: 15px;
}

.help-card h3 {
  font-size: 1.25rem;
  color: #ff5722;
  margin: 10px 0;
}

.help-card p {
  font-size: 0.95rem;
  color: #333;
  line-height: 1.5;
}

.help-card:hover {
  border-color: #ff5722;
}

  
  /* Titles */
  h2 {
    color: #ff5722;
    text-align: center;
    margin-bottom: 20px;
  }
  
  p {
    color: #333;
    line-height: 1.6;
    text-align: justify;
    font-size: 1rem;
  }
  
  /* Featured Activities Section */
  .cards {
    display: flex;
    justify-content: center;
    gap: 0 20px;
  }
  
  .card h3 {
    text-align: center;
    margin-top: 10px;
  }
  
  /* Why Choose Us Section */
  .questions {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .question h3 {
    color: #ff5722;
  }
  
  .question p {
    color: #333;
    font-size: 1.05rem;
  }
  
 
  