/* General Gallery Styles */
.gallery-container {
    padding: 20px;
    text-align: center;
  }
  
  .gallery-title {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .gallery-image:hover {
    transform: scale(1.05);
  }
  
  /* Lightbox Styles */
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .lightbox-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1;
  }
  
  .lightbox-content {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .lightbox-image {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
  }
  
  .lightbox-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    color: white;
    font-size: 2rem;
    border: none;
    cursor: pointer;
  }
  
  .lightbox-prev,
  .lightbox-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    color: white;
    font-size: 2rem;
    border: none;
    cursor: pointer;
  }
  
  .lightbox-prev {
    left: 20px;
  }
  
  .lightbox-next {
    right: 20px;
  }
  