/* General Donate Page Styles */
.donate-container {
    font-family: 'Arial', sans-serif;
    padding: 40px 20px;
    color: #333;
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 40px;
  }
  
  .donate-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .donate-header h1 {
    font-size: 3rem;
    color: #ff5722;
  }
  
  .donate-header p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .donate-info {
    margin-bottom: 40px;
  }
  
  .donate-info h2 {
    font-size: 2rem;
    color: #ff5722;
    margin-bottom: 20px;
  }
  
  .donate-info p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .donate-note {
    margin-bottom: 40px;
  }
  
  .donate-note h2 {
    font-size: 2rem;
    color: #ff5722;
    margin-bottom: 20px;
  }
  
  .donate-note p {
    font-size: 1.2rem;
    color: #555;
  }
  
  /* Responsive Design for Smaller Screens */
  @media (max-width: 768px) {
    .donate-container {
      padding: 20px;
    }
  
    .donate-header h1 {
      font-size: 2.5rem;
    }
  
    .donate-info h2 {
      font-size: 1.8rem;
    }
  
    .donate-note h2 {
      font-size: 1.8rem;
    }
  
    .donate-info p, .donate-note p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .donate-header h1 {
      font-size: 2rem;
    }
  
    .donate-info h2, .donate-note h2 {
      font-size: 1.5rem;
    }
  
    .donate-info p, .donate-note p {
      font-size: 1rem;
    }
  }
  