/* Hero Component Styles */
.hero {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Slider Container */
  .hero-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    overflow: hidden;
  }
  
  /* Individual Slide */
  .hero-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1.5s ease-in-out;
  }
  
  /* Active Slide */
  .hero-slide.active {
    opacity: 1;
  }
  
  /* Hero Overlay */
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adds a dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  /* Content Styling */
  .hero-content {
    text-align: center;
    color: white;
    z-index: 1;
    padding: 20px;
  }
  
  .hero-title {
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #e06a45; /* Updated subtitle color */
    text-align: center;
    font-weight: bold;
    
  }
  
  /* Hero CTA Button */
  .hero-cta {
    background: transparent; /* Initially transparent background */
    color: white; /* White text */
    border: 2px solid #ff5722; /* White border */
    padding: 12px 24px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 50px; /* Rounded button */
    transition: all 0.3s ease-in-out; /* Smooth transitions */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Hover and Focus Effect */
  .hero-cta:hover,
  .hero-cta:focus {
    background: #ff5722; /* Orange background on hover */
    color: white; /* Text remains white */
    border: 2px solid #ff5722; /* Add border with orange color */
    transform: translateY(-2px); /* Slight lift on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Shadow on hover */
  }
  
  /* Active (Clicked) Effect */
  .hero-cta:active {
    background: #ff5722; /* Solid orange background */
    color: white; /* Text remains white */
    border: 2px solid #ff5722; /* Maintain orange border */
    transform: translateY(0); /* Remove lift on click */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  /* Responsive Layout */
  @media (max-width: 768px) {
    .hero-content {
      padding: 15px;
    }
  
    .hero-title {
      font-size: 2rem; /* Adjust title size */
    }
  
    .hero-subtitle {
      font-size: 1.2rem; /* Adjust subtitle size */
    }
  
    .hero-cta {
      font-size: 1rem; /* Adjust button size */
      padding: 10px 20px;
    }
  }
  
  @media (max-width: 480px) {
    .hero-content {
      padding: 10px;
    }
  
    .hero-title {
      font-size: 1.8rem; /* Smaller title */
    }
  
    .hero-subtitle {
      font-size: 1rem; /* Smaller subtitle */
    }
  
    .hero-cta {
      font-size: 0.9rem; /* Smaller button text */
      padding: 8px 16px;
    }
  }
  