/* Footer Section */
.footer {
    background-color: #333; /* Light Black background */
    color: #ffffff; /* White text color */
    padding: 20px 0;
    font-family: 'Arial', sans-serif;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .left {
    text-align: left;
  }
  
  .left h2 {
    color: #ff5722;
    font-size: 1.8rem;
    margin-bottom: 5px;
  }
  
  .left .tagline {
    color: #ffffff; /* White tagline text */
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .social-icons {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .social-icon {
    font-size: 1.5rem;
    color: #ffffff;
    margin: 0 10px;
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #ff5722;
  }
  
  .right {
    text-align: right;
  }
  
  .right p {
    color: #ffffff; /* White text for contact info */
    margin: 5px 0;
  }
  
  /* Bottom Divider */
  .footer-bottom {
    border-top: 1px solid #444; /* Thinner divider */
    font-size: 0.9rem;
    color: #ffffff; /* White text for footer bottom */
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    align-items: center; /* Centers vertically */
    text-align: center; /* Ensures text is aligned */
    padding: 0; /* Shorten the height of the bottom divider */
  }
  
  .footer-bottom p, a {
    color: white; /* White color */
    text-decoration: none;
    
    transition: color 0.3s ease;
  }
  
  .footer-bottom a {
    color: #ff5722; /* White color */
    text-decoration: none;
    
    transition: color 0.3s ease;
  }
  

  .footer-bottom a:hover {
    color: wheat; /* Hover color for the 'tirkey.in' link */
  }
  
  /* Mobile View */
  @media (max-width: 600px) {
    .footer-content {
      flex-direction: column;
      text-align: left; /* Left-align the content for mobile */
      padding: 20px;
    }
  
    .left h2 {
      font-size: 1.6rem;
      text-align: left;
    }
  
    .left .tagline {
      font-size: 0.9rem;
    }
  
    .social-icon {
      font-size: 1.2rem;
      margin: 0 9px;

      
    }
  
    .footer-bottom {
      font-size: 0.8rem;
    padding: 0 20px;
      text-align: center; /* Center align the footer text */
    }
  }
  